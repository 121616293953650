import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function LafamaModal({ showModal, modalText, handleClose }) {
 

  return (
    <>
      <Modal centered={true} animation={true} show={showModal} onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Алергени</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>{modalText}</strong></p>
          <p className="mt-4">
            Бихме искали да Ви предупредим, че по време на преработка,
            съхранение и приготвяне на продуктите, следи от алергени могат да се
            пренесат и в други продукти, които предлагаме. Поради тази причина 
            <strong> La Fama</strong> не е в състояние да гарантира, че всеки наш
            продукт не съдържа следи и от други алергени.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Затвори
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default LafamaModal;
