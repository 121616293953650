import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css/bundle";

const Categories = ({ categories, currentCategory }) => {
  const swiperRef = useRef();

  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  useEffect(() => {
    const categoryIndex = categories.findIndex(
      (el) => el.key === currentCategory
    );

    if (categoryIndex !== -1 && swiperRef?.current.swiper !== undefined) {
      swiperRef.current.swiper.slideToLoop(categoryIndex);
    }
  }, [currentCategory, categories]);
  return (
    <div className="sticky-top">
      <div className="categories-menu">
        {showPrevButton === true ? (
          <div className="cat-button-prev d-md-block d-none">
            <button
              type="button"
              onClick={() => swiperRef.current?.swiper?.slidePrev()}
              className="btn btn-warning rounded-circle me-1 btn-nav-category"
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="categories-menu-items ">
          <Swiper
            modules={[Navigation]}
            slidesPerView={"auto"}
            spaceBetween={30}
            mousewheel
            onSlideChange={(swiper) => {
              setShowPrevButton(!swiper?.isBeginning);
              setShowNextButton(!swiper?.isEnd);
            }}
            onSliderMove={(swiper) => {
              setShowPrevButton(!swiper?.isBeginning);
              setShowNextButton(!swiper?.isEnd);
            }}
            ref={swiperRef}
          >
            {categories?.map((category) => {
              return (
                <SwiperSlide key={category.id}>
                  <a
                    className={`category-item-element ${
                      currentCategory === category.key && "active-category"
                    }`}
                    href={`#${category.key}`}
                  >
                    <div className="menu-category-item">
                      <div className="menu-category-title">
                        {category?.title}
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {showNextButton === true ? (
          <button
            type="button"
            onClick={() => swiperRef.current?.swiper?.slideNext()}
            className="btn btn-warning rounded-circle next-button d-md-block d-none btn-nav-category"
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Categories;
