import { AppStoreButton, ButtonsContainer, GooglePlayButton } from "react-mobile-app-button";
import logo from "./logo.svg";

function OrderApp() {
    const APKUrl = "https://play.google.com/store/apps/details?id=com.foodbooking.clientapp";
    const IOSUrl = "https://apps.apple.com/us/app/foodbooking/id1039788926";

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    return (
        <>
            <div className="container">
                <div className="d-flex justify-content-center mb-5">
                    <h1>
                        <img
                            src={logo}
                            alt="LaFama Logo"
                            height={150}
                            className="logo mt-5"
                        />
                    </h1>
                </div>
                <h2 className="d-flex justify-content-center mb-3 lafama-color">
                    Поръчайте онлайн
                </h2>
                <div className="d-flex justify-content-center mt-5 mb-5">
                    <ButtonsContainer>
                        {(getMobileOperatingSystem() === 'Android' || getMobileOperatingSystem() === 'unknown') && <GooglePlayButton
                            url={APKUrl}
                            theme={"dark"}
                            className={"custom-style"}
                        />}

                        {(getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'unknown') && <AppStoreButton
                            url={IOSUrl}
                            theme={"dark"}
                            className={"custom-style"}
                        />}
                    </ButtonsContainer>
                </div>
                <h4 className="d-flex justify-content-center mb-5 text-center lafama-color">
                    Намерете ни във Foodbooking,
                    <br />
                    направете своята поръчка предварително и я
                    <br />
                    вземете сами от нашия ресторант.
                </h4>
                <h4 className="d-flex justify-content-center my-5 text-center lafama-color">
                    или
                </h4>
                <div className="d-flex justify-content-center my-5 text-center lafama-color">
                    <span className="glf-button" data-glf-cuid="27f5715a-26ad-4318-b501-5ac5d31934da" data-glf-ruid="95d0d8e9-a4ee-477f-818b-2be020ece197" >Поръчайте без приложение тук</span>
                </div>
            </div>
        </>
    );
}

export default OrderApp;
