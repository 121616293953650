import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="row mt-5 mb-5">
      <div className="col-12 d-flex justify-content-center">
        <div className="phone">
          <FontAwesomeIcon icon={faWhatsapp} />
          <a href="https://wa.me/0879544644" className="ms-1">
            087 954 4644
          </a>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-3">
        <div className="instagram">
          <FontAwesomeIcon icon={faInstagram} />
          <a href="https://instagram.com/la.fama.official" className="ms-1">
            la.fama.official
          </a>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-3">
        <div className="location">
          <FontAwesomeIcon icon={faLocationPin} />
          <a
            href="https://www.google.com/maps/@42.6819751,26.315483,3a,34.5y,262.22h,85.21t/data=!3m6!1e1!3m4!1sdrlArDy-ywygJK2eFpjwxA!2e0!7i16384!8i8192"
            className="ms-1"
          >
            гр. Сливен, пл. Хаджи Димитър 11
          </a>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-5">
        <div className="creator">
          Дигитално меню от
          <a href="https://ayordanov.com" className="ms-1">
            ayordanov.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
